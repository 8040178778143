<template>
  <div v-if="sortBy" class="flex h-full items-center">
    <Tippy
      is="button"
      v-if="currentSort?.sortBy === sortBy"
      :content="`Sort By ${title} ${orderText}`"
      :class="[
        'Btn--plain',
        currentSort?.sortBy === sort.sortBy ? 'bg-gray-200' : ''
      ]"
      type="button"
      @click="onSort(sort.sortOrder === 'asc' ? 'desc' : 'asc')"
    >
      <Icon
        :name="sort.sortOrder === 'desc' ? 'bars-arrow-down' : 'bars-arrow-up'"
        class="'h-3.5 w-3.5 text-gray-600"
      />
    </Tippy>
    <DropDown v-else v-slot="{ open }" :items="options" size="sm">
      <Tippy
        :content="`Sort By ${title}`"
        :class="['Btn--plain', open ? 'bg-gray-200' : '']"
        type="button"
      >
        <Icon name="arrows-up-down" class="'h-3 w-3 text-gray-600" />
      </Tippy>
    </DropDown>
  </div>
</template>

<script setup lang="ts">
export type SortProps = {
  title?: string
  sortBy?: string
  currentSort?: Sort
}
const props = defineProps<SortProps>()
const emit = defineEmits<{ (e: 'sort', value: Sort): void }>()

const sort = ref<Sort>({
  sortOrder:
    props.currentSort && props.sortBy === props.currentSort.sortBy
      ? props.currentSort.sortOrder
      : 'desc',
  sortBy: props.sortBy ?? ''
})

const orderText = computed(() =>
  sort.value.sortOrder === 'desc' ? 'In Ascending Order' : 'In Descending Order'
)

const options: NavItem[] = [
  { title: 'Ascending', icon: 'bars-arrow-up', handler: () => onSort('asc') },
  {
    title: 'Descending',
    icon: 'bars-arrow-down',
    handler: () => onSort('desc')
  }
]

const onSort = (sortOrder: Sort['sortOrder']) => {
  if (!props.sortBy) return
  sort.value = { ...sort.value, sortOrder }
  emit('sort', sort.value)
}
</script>
